
import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';

import { getItem, clearStorage } from '../../../utils/localStorage';
import { makeRequest } from '../../../utils/makeRequest'
import { checkJwtExpiration } from '../../../utils/urls';
function Nav() {
    const location = useLocation();
    const currentPath = location.pathname;
    const navigate = useNavigate()
    const [userName, setFullname] = useState("")
    const [userRole, setRole] = useState("")

    const HandleLogOut = async () => {
        await clearStorage()
        navigate('/')
    }
    const getCurrentUser = async () => {
        try {
            const currentUser = await getItem('USER')
            if (currentUser) {
                const fullName = currentUser.user.fullName
                const role = currentUser.user.type
                setFullname(fullName)
                setRole(role)
            }
        }
        catch (err) {
            console.log(err.message)
        }
    }
    const confirmToken = async () => {
        try {
            const userDetails = await getItem('USER')
            const token = userDetails.authToken
            const response = await makeRequest(checkJwtExpiration, 'POST', {
                token: token
            })
            
            if(response.success === false){
                await clearStorage()
                     navigate('/')
            }
            
        }
        catch (err) {
            console.log(err.message)
        }
    }
    useEffect(() => {
        getCurrentUser()
        //confirmToken()

    }, [])
    return (
        <nav className="pc-sidebar">
            <div className="navbar-wrapper">
                <div className="m-header">
                    <Link to={"/core/dashboard"} className="b-brand text-primary">

                        <img
                            src="/assets/images/PayServeLogoFinal.png"
                            className="img-fluid logo-lg"
                            alt="logo"
                            style={{ width: 180 }}
                        />
                        <span className="badge bg-light-success rounded-pill ms-2 theme-version">
                            v1.0
                        </span>
                    </Link>
                </div>
                <div className="navbar-content" style={{ overflowY: 'scroll' }}>
                    <div className="card pc-user-card">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                    <img
                                        src="/assets/images/user/avatar-1.jpg"
                                        alt="user-image"
                                        className="user-avtar wid-45 rounded-circle"
                                    />
                                </div>
                                <div className="flex-grow-1 ms-3 me-2">
                                    <h6 className="mb-0">{userName}</h6>
                                    <small>{userRole}</small>
                                </div>
                                <Link
                                    className="btn btn-icon btn-link-secondary avtar"
                                    data-bs-toggle="collapse"
                                    to="#pc_sidebar_userlink"
                                >
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-sort-outline" />
                                    </svg>
                                </Link>
                            </div>
                            <div className="collapse pc-user-links" id="pc_sidebar_userlink">
                                <div className="pt-3">

                                    <Link to={"/core/settings"}>
                                        <i className="ti ti-settings" />
                                        <span>Settings</span>
                                    </Link>

                                    <Link to={"#!"} onClick={() => { HandleLogOut() }}>
                                        <i className="ti ti-power" />
                                        <span>Logout</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="pc-navbar mb-5">
                        <li className={`pc-item ${currentPath === '/core/dashboard' ? 'active' : ''}`}>
                            <Link to={'/core/dashboard'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-home" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Dashboard</span>


                            </Link>
                        </li>



                        <li className="pc-item pc-caption">
                            <label>Water Management</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-presentation-chart" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/water_concentrators' || currentPath === '/core/add_water_concentrator' || currentPath === '/core/import_water_concentrators' ? 'active' : ''}`}>
                            <Link to={'/core/water_concentrators'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-setting-outline" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Concentrators</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/water_meters' || currentPath === '/core/add_water_meter' || currentPath === '/core/import_water_meters' ? 'active' : ''}`}>
                            <Link to={'/core/water_meters'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-level" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Meters</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/water_meters' || currentPath === '/core/add_water_meter' || currentPath === '/core/import_water_meters' ? 'active' : '' || currentPath === '/core/issue_water_meters' ? 'active' : ''}`}>
                            <Link to={'/core/water_meters'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-share-bold" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Issue Meters</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/water_accounts' ? 'active' : ''}`}>
                            <Link to={'/core/water_accounts'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-notification-status" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Accounts</span>
                            </Link>
                        </li>

                        {/* <li className="pc-item pc-caption">
                            <label>Power Management</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-layer" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/power_gateways' ? 'active' : ''}`}>
                            <Link to={'/core/power_gateways'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-setting-outline" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Gateways</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/power_meters' ? 'active' : ''}`}>
                            <Link to={'/core/power_meters'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-level" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Meters</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/power_accounts' ? 'active' : ''}`}>
                            <Link to={'/core/power_accounts'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-notification-status" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Accounts</span>
                            </Link>
                        </li> */}


                        <li className="pc-item pc-caption">
                            <label>Business Partner Management</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-layer" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/companies' || currentPath === '/core/add_company' ? 'active' : ''}`}>
                            <Link to={'/core/companies'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-data" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Business Partners</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/facilities' ? 'active' : ''}`}>
                            <Link to={'/core/facilities'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-row-vertical" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Facilities</span>
                            </Link>
                        </li>


                        {/* <li className="pc-item pc-caption">
                            <label>Support</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-layer" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/tickets' ? 'active' : ''}`}>
                            <Link to={'/core/tickets'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-text-block" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Tickets</span>
                            </Link>
                        </li>
                        <li className="pc-item pc-caption">
                            <label>More</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-layer" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/api_metrics' ? 'active' : ''}`}>
                            <Link to={'/core/api_metrics'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-presentation-chart" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">API Metrics</span>
                            </Link>
                        </li> */}



                    </ul>
                </div>
            </div>
        </nav>

    )
}
export default Nav