import { Link } from 'react-router-dom'
import Layout from '../component/layout'
function Tickets() {
  return (
    <Layout>
      <div className="row">
        {/* support-section start */}
        <div className="col-xl-4 col-md-6">
          <div className="card support-bar">
            <div className="card-body pb-0">
              <h2 className="m-0">350</h2>
              <span className="text-info">Support Requests</span>
              <p className="mb-3 mt-3">
                Total number of support requests that come in.

              </p>
            </div>
            <div id="support-chart" style={{ height: 100, width: "100%" }} />
            <div className="card-footer bg-info text-white">
              <div className="row text-center">
                <div className="col border-end">
                  <h4 className="m-0 text-white">10</h4>
                  <span>Open</span>
                </div>
                <div className="col border-end">
                  <h4 className="m-0 text-white">5</h4>
                  <span>Running</span>
                </div>
                <div className="col">
                  <h4 className="m-0 text-white">3</h4>
                  <span>Solved</span>
                </div>
              </div>
            </div>

          </div>
          <div style={{ textAlign: 'center' }}>
            <Link to="/core/view_tickets"><strong>View Tickets</strong></Link>
          </div>
        </div>


        {/* support-section2 end */}
        {/* customer-section start */}
        <div className="col-xl-7 col-md-6">
          <div className="card">
            <div className="card-body">
              <div>
                <h6>New Ticket</h6>

              </div>

              <br />
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Subject:</label>

                    <input type="text" className="form-control mt-2" />
                  </div>
                  <div className="form-group mt-2">
                    <label>Issue:</label>
                    <select type="text" className="form-control mt-2" >
                      <option> -- Select --</option>
                      <option value="Paybill Registration">Paybill Registration</option>
                      <option value="Project Setup">Project Setup</option>
                      <option value="Meter Installation">Meter Installation</option>
                      <option value="Transaction Not Reflected">Transaction Not Reflected</option>
                      <option value="Meter Not Opened">Meter Not Opened</option>
                      <option value="Account Balance Issue">Account Balance Issue</option>
                      <option value="Customer Account Report">Customer Account Report</option>
                      <option value="Project Monthly Report">Project Monthly Report</option>
                    </select>
                  </div>
                  <div className="form-group mt-2">
                    <label>Description:</label>
                    <textarea type="text" className="form-control mt-2" />
                  </div>

                  <div className="form-group mt-2">
                    <label>Requester:</label>

                    <input type="text" className="form-control mt-2" />
                  </div>
                  <div className="form-group mt-2">
                    <label>Assigned To:</label>

                    <input type="text" className="form-control mt-2" />
                  </div>

                  <div className="text-end mt-4">
                    <button type="submit" className="btn btn-outline-secondary" >
                      Clear
                    </button>
                    &nbsp;
                    <button type="submit" className="btn btn-primary" >
                      Submit
                    </button>
                  </div>


                </div>
              </div>

            </div>
          </div>

        </div>
        {/* customer-section end */}

      </div>

    </Layout>
  )
}
export default Tickets