import { Link } from 'react-router-dom'
import Layout from '../component/layout'
function Dashboard() {
  return (
    <Layout>
      <div className="row">
        <div className="col-12">

        </div>
        <div className="col-md-6 col-xxl-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <div className="avtar avtar-s bg-light-primary">
                    <span className="pc-micon">
                      <svg className="pc-icon">
                        <use xlinkHref="#custom-data" />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="flex-grow-1 ms-3">
                  <h6 className="mb-0">All Companies</h6>
                </div>

              </div>
              <div className="bg-body p-3 mt-3 rounded">
                <div className="mt-3 row align-items-center">

                  <div className="col-5">
                    <h5 className="mb-1">5</h5>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <div className="avtar avtar-s bg-light-warning">
                    <span className="pc-micon">
                      <svg className="pc-icon">
                        <use xlinkHref="#custom-add-item" />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="flex-grow-1 ms-3">
                  <h6 className="mb-0">Projects</h6>
                </div>

              </div>
              <div className="bg-body p-3 mt-3 rounded">
                <div className="mt-3 row align-items-center">

                  <div className="col-5">
                    <h5 className="mb-1">10</h5>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <div className="avtar avtar-s bg-light-success">
                    <span className="pc-micon">
                      <svg className="pc-icon">
                        <use xlinkHref="#custom-profile-2user-outline" />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="flex-grow-1 ms-3">
                  <h6 className="mb-0">Customers</h6>
                </div>
                <div className="flex-shrink-0 ms-3">

                </div>
              </div>
              <div className="bg-body p-3 mt-3 rounded">
                <div className="mt-3 row align-items-center">

                  <div className="col-5">
                    <h5 className="mb-1">839</h5>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <div className="avtar avtar-s bg-light-danger">
                    <span className="pc-micon">
                      <svg className="pc-icon">
                        <use xlinkHref="#custom-dollar-square" />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="flex-grow-1 ms-3">
                  <h6 className="mb-0">Transactions</h6>
                </div>

              </div>
              <div className="bg-body p-3 mt-3 rounded">
                <div className="mt-3 row align-items-center">

                  <div className="col-5">
                    <h5 className="mb-1">KES 2,067</h5>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3">
          <div className="card">
            <div className="card-header">
              <h5 className="mb-0">Water Concentrators</h5>
            </div>
            <div className="card-body">

              <div className="d-grid gap-2">

                <Link to="#" className="btn btn-link-secondary">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <span className="p-1 d-block bg-danger rounded-circle">
                        <span className="visually-hidden">New alerts</span>
                      </span>
                    </div>
                    <div className="flex-grow-1 mx-2">
                      <p className="mb-0 d-grid text-start">
                        <span className="text-truncate w-100">123456</span>
                      </p>
                    </div>
                  </div>
                </Link>
                <Link to="#" className="btn btn-link-secondary">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <span className="p-1 d-block bg-danger rounded-circle">
                        <span className="visually-hidden">New alerts</span>
                      </span>
                    </div>
                    <div className="flex-grow-1 mx-2">
                      <p className="mb-0 d-grid text-start">
                        <span className="text-truncate w-100">234567</span>
                      </p>
                    </div>
                  </div>
                </Link>
                <Link to="#" className="btn btn-link-secondary">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <span className="p-1 d-block bg-success rounded-circle">
                        <span className="visually-hidden">New alerts</span>
                      </span>
                    </div>
                    <div className="flex-grow-1 mx-2">
                      <p className="mb-0 d-grid text-start">
                        <span className="text-truncate w-100">183934</span>
                      </p>
                    </div>
                  </div>
                </Link>
              </div>

            </div>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="mb-0">Project overview</h5>

              </div>
              <div className="row align-items-center justify-content-center">
                <div className="col-md-6 col-xl-4">
                  <div className="mt-3 row align-items-center">
                    <div className="col-6">
                      <p className="text-muted mb-1">Live</p>
                      <h5 className="mb-0">34,686</h5>
                    </div>

                  </div>
                </div>
                <div className="col-md-6 col-xl-4">
                  <div className="mt-3 row align-items-center">
                    <div className="col-6">
                      <p className="text-muted mb-1">New</p>
                      <h5 className="mb-0">245</h5>
                    </div>

                  </div>
                </div>
                <div className="col-md-6 col-xl-4">
                  <div className="mt-3 d-grid">
                    <button className="btn btn-primary d-flex align-items-center justify-content-center gap-2">
                      <i className="ti ti-plus" /> Add project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card">
            <div className="card-body border-bottom pb-0">
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="mb-0">Transactions</h5>

              </div>
              <ul className="nav nav-tabs analytics-tab" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="analytics-tab-1"
                    data-bs-toggle="tab"
                    data-bs-target="#analytics-tab-1-pane"
                    type="button"
                    role="tab"
                    aria-controls="analytics-tab-1-pane"
                    aria-selected="true"
                  >
                    Today
                  </button>
                </li>

              </ul>
            </div>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="analytics-tab-1-pane"
                role="tabpanel"
                aria-labelledby="analytics-tab-1"
                tabIndex={0}
              >
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <div className="d-flex align-items-center">

                      <div className="flex-grow-1 ms-3">
                        <div className="row g-1">
                          <div className="col-6">
                            <h6 className="mb-0">Evans Mburu</h6>
                            <p className="text-muted mb-0">
                              <small>QWERYRUDJ12</small>
                            </p>
                          </div>
                          <div className="col-6 text-end">
                            <h6 className="mb-1">KES 210</h6>
                            <p className="text-info mb-0">
                              10:30
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                </ul>
              </div>

            </div>
            <div className="card-footer">
              <div className="row g-2">
                <div className="col-md-6">
                  <div className="d-grid">
                    <Link to="/core/transactions" className="btn btn-outline-secondary d-grid">
                      <span className="text-truncate w-100">
                        View all Transaction History
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-grid">
                    <button className="btn btn-primary d-grid">
                      <span className="text-truncate w-100">
                        Create new Transaction
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="mb-0">Total Income</h5>

              </div>
              <div id="total-income-graph" />
              <div className="row g-3 mt-3">
                <div className="col-sm-6">
                  <div className="bg-body p-3 rounded">
                    <div className="d-flex align-items-center mb-2">
                      <div className="flex-shrink-0">
                        <span className="p-1 d-block bg-primary rounded-circle">
                          <span className="visually-hidden">New alerts</span>
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <p className="mb-0">Water</p>
                      </div>
                    </div>
                    <h6 className="mb-0">
                      KES 120,000,000

                    </h6>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="bg-body p-3 rounded">
                    <div className="d-flex align-items-center mb-2">
                      <div className="flex-shrink-0">
                        <span className="p-1 d-block bg-warning rounded-circle">
                          <span className="visually-hidden">New alerts</span>
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <p className="mb-0">Rent</p>
                      </div>
                    </div>
                    <h6 className="mb-0">
                      KES 23,876

                    </h6>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="bg-body p-3 rounded">
                    <div className="d-flex align-items-center mb-2">
                      <div className="flex-shrink-0">
                        <span className="p-1 d-block bg-success rounded-circle">
                          <span className="visually-hidden">New alerts</span>
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <p className="mb-0">Power</p>
                      </div>
                    </div>
                    <h6 className="mb-0">
                      KES 200,000,000

                    </h6>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>

  )
}
export default Dashboard