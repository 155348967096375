export function transformEmail(email) {
    // Split email into username and domain
    const parts = email.split('@');
    const username = parts[0];
    const domain = parts[1];

    // Abbreviate username to 'jone' and mask the rest
    const maskedUsername = username.slice(0, 4) + '.'.repeat(username.length - 4);

    // Concatenate transformed email
    const transformedEmail = maskedUsername + '@' + domain;
    
    return transformedEmail;
}