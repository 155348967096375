import axios from 'axios';
import { backend_url } from './urls';
import { getItem } from './localStorage';


export async function makeRequest(url, method, body) {
    try {
        const options = {
            method: method,
            url: backend_url + url,
            data: (method === 'POST' || method === 'PUT') ? body : undefined,
            responseType: 'json',
        };

        const response = await axios(options);

        if (response.status === 200) {
            return { success: true, data: response.data };
        } else {
            return { success: false, error: `Error: ${response.status} - ${response.data.error}` };
        }
    } catch (error) {
        // Extract more detailed error information
        let errorMessage;
  
        if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            errorMessage = `Error: ${error.response.status} - ${error.response.data.error}`;
        } else if (error.request) {
            // The request was made but no response was received
            errorMessage = 'Error: No response received from server';
        } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = `Error: ${error.message}`;
        }
        return { success: false, error: errorMessage };
    }
}


export async function makeRequest2(url, method, body) {
    try {

        const userDetails = await getItem('USER')
        const token = userDetails.authToken
        const options = {
            method: method,
            url: backend_url + url,
            data: (method === 'POST' || method === 'PUT') ? body : undefined,
            responseType: 'json',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        const response = await axios(options);

        if (response.status === 200) {
            return { success: true, data: response.data };
        } else {
            return { success: false, error: `Error: ${response.status} - ${response.data.error}` };
        }
    } catch (error) {
        // Extract more detailed error information
        let errorMessage;
  
        if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            errorMessage = `Error: ${error.response.status} - ${error.response.data.error}`;
        } else if (error.request) {
            // The request was made but no response was received
            errorMessage = 'Error: No response received from server';
        } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = `Error: ${error.message}`;
        }
        return { success: false, error: errorMessage };
    }
}

export async function makeRequest3(url, method, body) {
    try {

        const userDetails = await getItem('USER')
        const token = userDetails.authToken
        const options = {
            method: method,
            url: backend_url + url,
            data: (method === 'POST' || method === 'PUT') ? body : undefined,
            responseType: 'json',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        };

        const response = await axios(options);

        if (response.status === 200) {
            return { success: true, data: response.data };
        } else {
            return { success: false, error: `Error: ${response.status} - ${response.data.error}` };
        }
    } catch (error) {
        // Extract more detailed error information
        let errorMessage;
  
        if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            errorMessage = `Error: ${error.response.status} - ${error.response.data.error}`;
        } else if (error.request) {
            // The request was made but no response was received
            errorMessage = 'Error: No response received from server';
        } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = `Error: ${error.message}`;
        }
        return { success: false, error: errorMessage };
    }
}