import Layout from '../component/layout'
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';
import { addWaterConcentratorURL } from '../../../utils/urls';
import { updateSpinner } from '../../../features/authentication/authenticationReducer';

function AddConcentrator() {
  const dispatch = useDispatch();
  const [manufacturer, setManufacturer] = useState('')
  const [serialNumber, setSerialNumber] = useState('')
  const [range, setRange] = useState(0)

  const HandleClearButton = () => {
    setManufacturer('');
    setSerialNumber('');
    setRange('')
  }
  const HandleSubmitButton = async () => {
    dispatch(updateSpinner(true))
    try {

      if (manufacturer === "") {
        throw new Error('Manufacturer is required.')
       
      }
      else if (serialNumber === "") {
        throw new Error('Serial Number is required.')
      
      }
      else if (range < 0) {
        throw new Error('Range should be greator than 0.')
     
      }
      else {
        const body = {
          serialNumber, manufacturer, range
        }
        const response = await makeRequest2(addWaterConcentratorURL, 'POST', body)
        if (response.success) {
          setTimeout(() => {
            dispatch(updateSpinner(false))
            toastify(response.data, 'success')
          }, 1000)


        }
        else {
          throw new Error(response.error)
        }
      }
    }
    catch (err) {
      dispatch(updateSpinner(false))
      toastify(err.message, 'error')
    }
  }

  return (
    <Layout>

      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/core/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/core/water_concentrators"}>Concentrators</Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  Add Concentrator
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="mb-4">
                <Link to={"/core/water_concentrators"}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>

              </div>
              <div className="container">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">Manufacturer<span style={{ color: 'red' }}>*</span></label>
                      <select className="mb-3 form-select" value={manufacturer} onChange={(e) => {
                        const value = e.target.value
                        setManufacturer(value)
                      }}>

                        <option value="">-- Select --</option>
                        <option value="PayServe">PayServe</option>

                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">Serial Number<span style={{ color: 'red' }}>*</span></label>
                      <input type="text" className="mb-3 form-control" value={serialNumber} onChange={(e) => {
                        const value = e.target.value
                        setSerialNumber(value)
                      }} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">Range</label>
                      <input type="number" value={range} className="mb-3 form-control" onChange={(e) => {
                        const value = e.target.value
                        setRange(value)
                      }} />
                    </div>
                  </div>
                </div>



                <div className="text-end mt-4">
                  <button type="submit" className="btn btn-outline-secondary" onClick={HandleClearButton}>
                    Clear
                  </button>
                  &nbsp;
                  <button type="submit" className="btn btn-primary" onClick={HandleSubmitButton}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </Layout>
  )
}
export default AddConcentrator