import React from 'react';
import { Link } from 'react-router-dom'

import Layout from '../component/layout'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-blue/theme.css';

function ViewTickets() {
 
  

 
  const header = (
    <div className="flex align-items-center justify-content-end gap-2">
      <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
      <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
      <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
    </div>
  );
  return (
    <Layout>


      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/core/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/core/tickets"}>Tickets</Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  View Tickets
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="mb-4">
                <Link to={"/core/tickets"}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>

              </div>
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">


                          <div className="col-3">
                            <br />
                            <input className="form-control" placeholder="Search here" />

                          </div>
                          <div className="col-8">
                            <div className="row">
                              <div className="col-4">
                                <label>Start Date</label>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-4">
                                <label>End Date</label>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-4">
                                <br />
                                <div className="btn-group-dropdown" >
                                  <button
                                    className="btn btn-outline-default dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="ti ti-filter"></i>
                                  </button>
                                  <div className="dropdown-menu">
                                    <Link className="dropdown-item" to="#!" >
                                      All
                                    </Link>
                                    <Link className="dropdown-item" to="#!"  >
                                      By Project
                                    </Link>

                                  </div>
                                </div>
                              </div>
                            </div>


                          </div>

                        </div>

                      </div>
                      <div className="card-body">
                        <div className="dt-responsive table-responsive">
                          <DataTable value={[]} header={header} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                            <Column field="no" sortable header="No"></Column>
                            <Column field="subject" sortable header="Subject"></Column>
                            <Column field="Issue" sortable header="Issue"></Column>
                            <Column field="Description" sortable header="Description"></Column>
                            <Column field="Status" sortable header="Status"></Column>
                            <Column field="Requester" sortable header="Requester"></Column>
                            <Column field="Assigned To" sortable header="Assigned To"></Column>
                          </DataTable>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>



              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}
export default ViewTickets