import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Layout from '../component/layout';
import { areaMeasurements } from '../../../utils/data';
import { makeRequest2 } from '../../../utils/makeRequest';
import { addUnitAsset, deleteUnitAsset, getUnitAsset, getUnitUrl, updateUnitName } from '../../../utils/urls';
import { toastify } from '../../../utils/toast';

// Sample data
const sampleUtilityHistory = [
    { serialNumber: '29292903', type: 'GGA' },
    { serialNumber: '444494949', type: 'GGD' }
];

const sampleAssets = [
    { name: 'Asset 1', action: 'Delete' },
    { name: 'Asset 2', action: 'Delete' }
];

const sampleUnits = [
    { name: 'Unit A' },
    { name: 'Unit B' }
];

const ViewUnit = () => {
    const navigate = useNavigate()
    const { unitId } = useParams();
    const [unit, setUnit] = useState({})
    const [utilityHistory, setUtilityHistory] = useState(sampleUtilityHistory);
    const [assets, setAssets] = useState(sampleAssets);
    const [units, setUnits] = useState(sampleUnits);
    const [assetName, setAssetName] = useState('');
    const [unitName, setUnitName] = useState('');
    const [unitAssets, setUnitAssets] = useState([])
    const [newAssetDialog, setNewAssetDialog] = useState(false);
    const [editUtilityDialog, setEditUtilityDialog] = useState(false);
    const [editUnitDialog, setEditUnitDialog] = useState(false);
    const [utilityName, setUtilityName] = useState('');
    const [selectedUtility, setSelectedUtility] = useState(null);
    const [selectedUnit, setSelectedUnit] = useState(null);


    const defaultMeasurement = areaMeasurements.find(measurement => measurement.default) || { symbol: '' };



    // const handleDeleteAsset = (asset) => {
    //     setAssets(assets.filter(a => a !== asset));
    // };
    const HandleDeleteAsset = async (id) => {
        try {
            const confirmDelete = window.confirm("Are you sure you want to delete this asset?");
            if (!confirmDelete) {
                return;
            }

            const response = await makeRequest2(deleteUnitAsset + '/' + id, 'DELETE')
            if (response.success) {
                toastify(response.data, 'success');
                getUnitAssets()
            }
            else {
                throw new Error(response.error)
            }
        }
        catch (err) {
            toastify(err.message, 'error')
        }
    };



    const getUnit = async () => {
        try {
            const response = await makeRequest2(getUnitUrl + '/' + unitId, 'GET')
            if (response.success === true) {
                const unit = response.data;
                setUnit(unit)
            }
            else {
                throw new Error(response.error)
            }
        }
        catch (err) {
            toastify(err.message, 'error')
        }
    }

    const getUnitAssets = async () => {
        try {
            const response = await makeRequest2(getUnitAsset + '/' + unitId, 'GET')
            console.log(response)
            if (response.success === true) {
                const unitAssets = response.data;
                setUnitAssets(unitAssets)
            }
            else {
                throw new Error(response.error)
            }
        }
        catch (err) {
            console.log(err.message)
            toastify(err.message, 'error')
        }
    }

    const HandleAssetSubmit = async () => {
        try {
            const name = assetName

            if (name === '') {
                throw new Error('Asset name is required.')
            }
            else {

                const response = await makeRequest2(addUnitAsset + '/' + unitId, 'POST', {
                    name
                }) // 
                console.log(response)
                if (response.success) {
                    toastify('Asset added successfully', 'success');
                    setAssetName('')
                    setNewAssetDialog(false)
                    getUnitAssets()


                }
                else {
                    throw new Error(response.error)
                }
            }

        }
        catch (err) {
            console.log(err)
            toastify(err.message, 'error')
        }
    }

    const handleUpdateName = async () => {
        try {
            // const name = unitName;
            if (unitName === '') {
                throw new Error('Unit Name is required')
            }
            const response = await makeRequest2(`${updateUnitName}/${unitId}`, 'POST', { unitName })
            console.log(response)
            if (response.success) {
                toastify(response.data, 'success');
                setUnitName('')
                getUnit()
            }
            else {
                throw new Error(response.error)
            }

        } catch (err) {
            console.log(err)
            toastify(err.message, 'error')
        }
    }

    const deleteAssetTemplate = (rowData) => {
        return (
            <Link
                to={'#'}
                className="avtar avtar-xs btn-link-secondary btn-pc-default"

                onClick={() => { HandleDeleteAsset(rowData._id) }}
            >
                <i className="ti ti-trash f-18" style={{ color: 'red' }}></i>
            </Link>
        )
    }

    useEffect(() => {
        getUnit()
        getUnitAssets()
    }, [])



    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/core/dashboard/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Unit Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>View Unit</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <Link to={"#"} onClick={() => navigate(-1)}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body py-0">
                                    <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="profile-tab-1" data-bs-toggle="tab" href="#profile-1" role="tab" aria-selected="true">
                                                <i className="ti ti-building-bank me-2"></i>Info
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="profile-tab-2" data-bs-toggle="tab" href="#profile-2" role="tab" aria-selected="true">
                                                <i className="ti ti-building-warehouse me-2"></i>Utility History
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="profile-tab-3" data-bs-toggle="tab" href="#profile-3" role="tab" aria-selected="true">
                                                <i className="ti ti-olympics me-2"></i>Assets
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="profile-tab-4" data-bs-toggle="tab" href="#profile-4" role="tab" aria-selected="true">
                                                <i className="ti ti-file-text me-2"></i>Documents
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="profile-tab-5" data-bs-toggle="tab" href="#profile-5" role="tab" aria-selected="true">
                                                <i className="ti ti-settings me-2"></i>Settings
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div className="tab-pane show active" id="profile-1" role="tabpanel" aria-labelledby="profile-tab-1">
                                    <div className="row">
                                        <div className="col-lg-4 col-xxl-3">
                                            <div className="card">
                                                <div className="card-body position-relative">
                                                    <div className="text-center mt-3">
                                                        <div className="chat-avtar d-inline-flex mx-auto">
                                                            <i className="ti ti-building-bank me-2" style={{ fontSize: 100 }}></i>
                                                        </div>
                                                        <h5 className="mb-0"></h5>
                                                        <h2 className="text-muted mt-2">{unit.name}</h2>
                                                        <hr className="my-3 border border-secondary-subtle" />

                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>Unit Type: </strong>{unit.unitType}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>Division: </strong>{unit.division}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>Floors Unit No: </strong>0 {defaultMeasurement.symbol}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>lettableFloorArea: </strong>123456</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>landRateNumber: </strong>1500 {defaultMeasurement.symbol}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="profile-2" role="tabpanel" aria-labelledby="profile-tab-2">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="dt-responsive table-responsive">
                                                <DataTable value={utilityHistory} emptyMessage="No utility history available." sortMode="multiple" paginator rows={10} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                                    <Column field="serialNumber" header="Serial Number" sortable></Column>
                                                    <Column field="type" header="Type" sortable></Column>
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="profile-3" role="tabpanel" aria-labelledby="profile-tab-3">
                                    <div className="card">
                                        <div className="card-header">
                                            <div style={{ float: 'right' }}>
                                                <button className="btn btn-primary" onClick={() => setNewAssetDialog(true)}>Add New Asset</button>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <DataTable value={unitAssets} emptyMessage="No assets found." sortMode="multiple" paginator rows={10} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                                        <Column field="name" sortable header="Name"></Column>
                                                        <Column field="action" sortable body={deleteAssetTemplate} header="Actions"></Column>
                                                    </DataTable>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="profile-4" role="tabpanel" aria-labelledby="profile-tab-4">
                                    <div className="row">
                                        <div className="col-md-2" >
                                            <Link>
                                                <div className="card text-center" style={{ paddingTop: 25, paddingBottom: 20 }}>
                                                    <i className="ti ti-file-download" style={{ fontSize: 50 }}></i>
                                                    <strong className="mt-2">Tax Certificate</strong>

                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col-md-2" >
                                            <Link><div className="card text-center" style={{ paddingTop: 25, paddingBottom: 20 }}>
                                                <i className="ti ti-file-download" style={{ fontSize: 50 }}></i>
                                                <strong className="mt-2">Company Certificate</strong>

                                            </div></Link>
                                        </div>
                                        <div className="col-md-2" >
                                            <Link>
                                                <div className="card text-center" style={{ paddingTop: 25, paddingBottom: 20 }}>
                                                    <i className="ti ti-file-download" style={{ fontSize: 50 }}></i>
                                                    <strong className="mt-2">ID / Passport</strong>

                                                </div></Link>



                                        </div>

                                    </div>
                                </div>

                                <div className="tab-pane fade" id="profile-5" role="tabpanel" aria-labelledby="profile-tab-5">
                                    <div className="card">

                                        <div className="card-body py-0">
                                            <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">


                                                <li className="nav-item">
                                                    <a className="nav-link active" id="setting-tab-1" data-bs-toggle="tab" href="#setting-1" role="tab" aria-selected="true">
                                                        <i className="ti ti-edit me-2"></i>Edits
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>
                                        <div className="tab-content">

                                            <div className="tab-pane active" id="setting-1" role="tabpanel" aria-labelledby="setting-tab-1">
                                                <div className="row">
                                                    <div className="col-lg-4">

                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="card">
                                                            <div className="card-header"></div>
                                                            <div className="card-body">
                                                                <label>Unit Name<span style={{ color: 'red' }}>*</span>:</label>
                                                                <input className="form-control mt-2" type="text" placeholder="Enter unit name" value={unitName} onChange={(e) => {
                                                                    let value = e.target.value;
                                                                    setUnitName(value)
                                                                }}></input>
                                                            </div>

                                                        </div>


                                                    </div>
                                                    <div className="col-lg-4">

                                                    </div>

                                                </div>

                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <Dialog header={`Add a New Asset`} visible={newAssetDialog} style={{ width: '30vw' }} onHide={() => { if (!newAssetDialog) return; setNewAssetDialog(false); }}>

                <div className="row">
                    <div className="col-md-12 mt-3">
                        <div >
                            <label>Asset Name:</label>
                            <input className="form-control mt-2" type="text" placeholder="Enter Asset Name" value={assetName} onChange={(e) => {
                                const value = e.target.value;
                                setAssetName(value)

                            }}></input>
                        </div>

                        <div className='mt-4' style={{ float: 'right' }}>
                            <button className="btn btn-primary" onClick={HandleAssetSubmit}>Submit</button>
                        </div>

                    </div>

                </div>



            </Dialog>



        </Layout>
    );
};






export default ViewUnit;
