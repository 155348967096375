
import React, { useState, useEffect } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { toastify } from '../../../utils/toast'
import Layout from '../component/layout'
import { makeRequest2 } from '../../../utils/makeRequest';
import { getCompanyDetails, EnableCompany, DisableCompany, deleteDivisionFacilitiesURL, addDivisionFacilitiesURL } from '../../../utils/urls';
import { updateCurrentFacility } from '../../../features/core/coreReducer';
function ViewCompany() {
    const navigate = useNavigate()
    const { companyId } = useParams();
    const [company, setCompany] = useState({})
    const [facilities, setFacility] = useState([])
    const [divisions, setDivisions] = useState([])
    const [currentFacility, setCurrentFacility] = useState('')
    const [currentFacilityId, setCurrentFacilityId] = useState('')
    const [currentsubDivision, setSubDivision] = useState('')
    const [subDivisionType, setSubDivisionType] = useState('Block');
    const [divisionArray, setDivisionArray] = useState([])
    const [visibleDivision, setVisibleDivision] = useState(false)
    const [ArrayDivisions, setArrayDivisions] = useState([])
    const [visibleAddDivision, setVisibleAddDivision] = useState(false)

    const [numberOfDivision, setNumberofDivision] = useState(0)


    const getCompany = async () => {
        try {
            const response = await makeRequest2(getCompanyDetails + '/' + companyId, 'GET')
            if (response.success) {
                setCompany(response.data.company)
                setFacility(response.data.facilities)
            }
        }
        catch (err) {
            toastify('Server error.', 'error')
        }
    }

    useEffect(() => {
        getCompany()
    }, [])
    const isEnabledBodyTemplate = (rowData) => {
        if (rowData.isEnabled !== undefined) {
            if (rowData.isEnabled) {
                return (

                    <span className="badge rounded-pill text-bg-success">Enabled</span>
                )
            }
            else {
                return (

                    <span className="badge rounded-pill text-bg-danger">Disabled</span>
                )
            }
        }


    };

    const divisionsTemplate = (rowData) => {

        return (
            <ul className="list-inline me-auto mb-0">
                <li className="list-inline-item align-bottom" data-bs-toggle="tooltip" title="View">
                    <Link
                        to={'#'}
                        className="avtar avtar-xs btn-link-secondary btn-pc-default"
                        onClick={() => {
                            setVisibleDivision(true)
                            setDivisions(rowData.divisionArray)
                            setCurrentFacility(rowData.name)
                            setSubDivision(rowData.subDivision)
                            setCurrentFacilityId(rowData._id)
                        }}
                    >
                        <i className="ti ti-eye f-18"></i>
                    </Link>
                    <Link
                        to={'#'}
                        className="avtar avtar-xs btn-link-secondary btn-pc-default"
                        onClick={() => {
                            setVisibleAddDivision(true)
                            setDivisions(rowData.divisionArray)
                            setCurrentFacility(rowData.name)
                            setSubDivision(rowData.subDivision)
                            setCurrentFacilityId(rowData._id)
                        }}
                    >
                        <i className="ti ti-circle-plus f-18"></i>
                    </Link>
                </li>


            </ul>
        )
    }
    const handleDisableCompany = async () => {
        try {
            const response = await makeRequest2(DisableCompany + '/' + companyId, 'GET', {})
            if (response.success) {
                getCompany()
            }
        }
        catch (err) {
            toastify(err.message, 'error')
        }
    }
    const handleEnableCompany = async () => {
        try {
            const response = await makeRequest2(EnableCompany + '/' + companyId, 'GET', {})
            if (response.success) {
                getCompany()
            }
        }
        catch (err) {
            toastify(err.message, 'error')
        }

    }
    const HandleDeleteDivision = async (id, divisions) => {
        try {
            const response = await makeRequest2(deleteDivisionFacilitiesURL + '/' + id, 'POST', {
                divisions
            })
            if (response.success) {
                getCompany()

            }
        }
        catch (err) {
            toastify(err.message, 'error')
        }
    }

    const HandleSaveFacilityData = async (id) => {
        try {
            console.log(id)
            console.log(divisionArray)
            const response = await makeRequest2(addDivisionFacilitiesURL + '/' + id, 'POST', {
                divisionArray
            })
            console.log(response.success)
            if (response.success) {
                toastify('Successfully added.', 'success')
                setVisibleAddDivision(false)
                getCompany()


            }
        }
        catch (err) {
            toastify(err.message, 'error')
        }

    }
    const nameTemplate =  (rowData) => {
        return (<Link to={'/core/facilities/view_facility/'+rowData._id}>
            <strong>{rowData.name}</strong>
        </Link>)
    }
    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/core/dashboard/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/core/companies"}>Business Partners</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">


                    <div className="row">

                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <Link to={"/core/companies"}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>

                                                </div>
                                            </div>
                                            <div className="card">

                                                <div className="card-body py-0">
                                                    <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active" id="profile-tab-1" data-bs-toggle="tab" href="#profile-1" role="tab" aria-selected="true">
                                                                <i className="ti ti-building-bank me-2"></i>Info
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="profile-tab-2" data-bs-toggle="tab" href="#profile-2" role="tab" aria-selected="true">
                                                                <i className="ti ti-building-warehouse me-2"></i>Facilities
                                                            </a>
                                                        </li>


                                                        {/* <li className="nav-item">
                                                            <a className="nav-link" id="profile-tab-5" data-bs-toggle="tab" href="#profile-3" role="tab" aria-selected="true">
                                                                <i className="ti ti-coin me-2"></i>Statement of Accounts
                                                            </a>
                                                        </li> */}
                                                        
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="profile-tab-5" data-bs-toggle="tab" href="#profile-4" role="tab" aria-selected="true">
                                                                <i className="ti ti-file-like me-2"></i>Documents
                                                            </a>
                                                        </li>



                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="tab-content">
                                                <div className="tab-pane show active" id="profile-1" role="tabpanel" aria-labelledby="profile-tab-1">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-xxl-3">
                                                            <div className="card">
                                                                <div className="card-body position-relative">

                                                                    <div className="text-center mt-3">
                                                                        <div className="chat-avtar d-inline-flex mx-auto">
                                                                            <i className="ti ti-building-bank me-2" style={{ fontSize: 100 }}></i>
                                                                        </div>
                                                                        <h5 className="mb-0"></h5>
                                                                        <h2 className="text-muted mt-2">{company.name}</h2>
                                                                        <hr className="my-3 border border-secondary-subtle" />
                                                                        <div className="row g-3">
                                                                            <div className="col-4">

                                                                            </div>
                                                                            <div className="col-4 border border-top-0 border-bottom-0">
                                                                                <h5 className="mb-0">{company.facilities !== undefined && company.facilities.length}</h5>
                                                                                <small className="text-muted">Facilities</small>
                                                                            </div>
                                                                            <div className="col-4">

                                                                            </div>
                                                                        </div>
                                                                        <hr className="my-3 border border-secondary-subtle" />

                                                                        {
                                                                            company.address !== '' &&
                                                                            <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">

                                                                                <p className="mb-0"><strong>Address: </strong>{company.address}</p>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            company.city !== '' && <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">

                                                                                <p className="mb-0"><strong>City: </strong>{company.city}</p>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            company.country !== '' && <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                                                <p className="mb-0"><strong>Country: </strong>{company.city}</p>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            company.registrationNumber !== '' && <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                                                <p className="mb-0"><strong>Company Registration Number: </strong>{company.registrationNumber}</p>
                                                                            </div>
                                                                        }



                                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                                            <span>Created At: </span>
                                                                            <p className="mb-0">{company.createdAt}</p>
                                                                        </div>
                                                                        <div className="w-100 mb-3">
                                                                            {
                                                                                company.isEnabled ?
                                                                                    <button className="btn btn-danger btn-block" onClick={handleDisableCompany}>Disable Company</button>
                                                                                    :
                                                                                    <button className="btn btn-primary btn-block" onClick={handleEnableCompany}>Enable Company</button>
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="profile-2" role="tabpanel" aria-labelledby="profile-tab-2">
                                                    <div className="row">
                                                        <div className="card-header">
                                                            <div style={{ float: 'right' }}>
                                                                <button className="btn btn-primary" onClick={() => {
                                                                    navigate('/core/facilities/add_facility/' + company._id)
                                                                }}>Add New Facility</button>

                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <DataTable value={facilities} emptyMessage="No facilities found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>

                                                                <Column field="name" sortable header="Name" body={nameTemplate}></Column>
                                                                <Column field="location" header="Location"></Column>
                                                                <Column field="subDivision" header="Sub Division"></Column>
                                                                <Column field="subDivision" body={divisionsTemplate} header="Divisions"></Column>
                                                                <Column field="subDivision" body={isEnabledBodyTemplate} header="IsEnabled"></Column>


                                                            </DataTable>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="profile-3" role="tabpanel" aria-labelledby="profile-tab-3">
                                                    <div className="row">
                                                        <div className="card-body">
                                                            <DataTable value={[]} emptyMessage="No data found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>


                                                            </DataTable>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="profile-4" role="tabpanel" aria-labelledby="profile-tab-4">
                                                    <div className="row">
                                                        <div className="col-md-2" >
                                                            <Link>
                                                                <div className="card text-center" style={{ paddingTop: 25, paddingBottom: 20 }}>
                                                                    <i className="ti ti-file-download" style={{ fontSize: 50 }}></i>
                                                                    <strong className="mt-2">Tax Certificate</strong>

                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className="col-md-2" >
                                                            <Link><div className="card text-center" style={{ paddingTop: 25, paddingBottom: 20 }}>
                                                                <i className="ti ti-file-download" style={{ fontSize: 50 }}></i>
                                                                <strong className="mt-2">Company Certificate</strong>

                                                            </div></Link>
                                                        </div>
                                                        <div className="col-md-2" >
                                                            <Link>
                                                                <div className="card text-center" style={{ paddingTop: 25, paddingBottom: 20 }}>
                                                                    <i className="ti ti-file-download" style={{ fontSize: 50 }}></i>
                                                                    <strong className="mt-2">ID / Passport</strong>

                                                                </div></Link>



                                                        </div>

                                                    </div>


                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>

                </div>
            </div>
            <Dialog header={currentFacility} visible={visibleDivision} style={{ width: '30vw' }} onHide={() => { if (!visibleDivision) return; setDivisions([]); setVisibleDivision(false); }}>
                <table className="table">
                    <thead>
                        <tr>
                            <th>{currentsubDivision}(s)</th>
                            <th>Floors</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            divisions.map((division, index) => {
                                return <>
                                    <tr>
                                        <td>{division.divisionName}</td>
                                        <td>{division.NoOfFloors}</td>
                                        <td> <Link
                                            to={'#'}
                                            className="avtar avtar-xs btn-link-secondary btn-pc-default"
                                            onClick={() => {
                                                let array = divisions.filter((x) => {
                                                    return x.divisionName !== division.divisionName
                                                })
                                                setDivisions(array)



                                                HandleDeleteDivision(currentFacilityId, array)
                                            }}
                                        >
                                            <i className="ti ti-trash f-18" style={{ color: 'red' }}></i>
                                        </Link></td>
                                    </tr>
                                </>
                            })
                        }
                    </tbody>
                </table>

            </Dialog>
            <Dialog header={`Add a New Sub Division`} visible={visibleAddDivision} style={{ width: '30vw' }} onHide={() => { if (!visibleAddDivision) return; setVisibleAddDivision(false); }}>
                <div className="row">
                    <div className="col-6 mt-2">
                        <label className="form-label" for="schoolLocation">Sub Division Type <span style={{ color: 'red' }}>*</span></label>
                        <select type="text" className="form-control" placeholder="Enter Subdivision Type" value={subDivisionType} onChange={(e) => {
                            let value = e.target.value;
                            setSubDivisionType(value)
                            setNumberofDivision(0)
                            setArrayDivisions([])
                            setDivisionArray([])
                            

                        }}>
                            <option value="">-- Select --</option>
                            <option value="Phase">Phases</option>
                            <option value="Zone">Zones</option>
                            <option value="Block">Blocks</option>
                            <option value="Court">Courts</option>
                            <option value="Cluster">Clusters</option>
                        </select>
                    </div>
                    <div className="col-6 mt-2">
                        <div className="mb-3">
                            <label className="form-label" for="schoolLocation">Number of {subDivisionType}(s) <span style={{ color: 'red' }}>*</span></label>
                            <input type="number" value={numberOfDivision} className="form-control" placeholder={`Enter number of ` + subDivisionType} onChange={(e) => {
                                let value = e.target.value;
                                const array = [];
                                let d = []
                                for (let i = 0; i < value; i++) {
                                    array.push(i + 1);
                                    d.push({
                                        divisionName: subDivisionType + '-' + (i + 1),
                                        NoOfFloors: 0

                                    })
                                }
                                setNumberofDivision(value)
                                setArrayDivisions(array)
                                setDivisionArray(d)
                                console.log(d)

                            }} />
                        </div>
                    </div>

                </div>
                <div className="row">
                    {
                        ArrayDivisions.map((item, index) => {
                            return < >
                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="form-label" for="schoolLocation">{subDivisionType} Name</label>
                                        <input type="text" className="form-control" placeholder={divisionArray[index].divisionName} onChange={(e) => {
                                            let array = divisionArray
                                            array[index].divisionName = e.target.value

                                            setDivisionArray(array)
                                        }} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="form-label" for="schoolLocation">No of {subDivisionType === 'Block' ? 'Floors' : 'Units'}</label>
                                        <input type="Number" className="form-control" placeholder={divisionArray[index].NoOfFloors} onChange={(e) => {

                                            let array = divisionArray
                                            array[index].NoOfFloors = e.target.value
                                            console.log(array)

                                            setDivisionArray(array)
                                        }} />
                                    </div>
                                </div>

                            </>
                        })
                    }
                </div>
                <div className="row mt-2">
                    <button className="btn btn-primary" onClick={() => { HandleSaveFacilityData(currentFacilityId) }}>Submit</button>
                </div>





            </Dialog>
        </Layout>
    )
}


export default ViewCompany