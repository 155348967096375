import { createSlice } from '@reduxjs/toolkit'

export const coreSlice = createSlice({
  name: 'Core',
  initialState: {
    currentUser:{},
    token:"",
    facility:{}
    

    
  },
  reducers: {
    increment: (state) => {
     
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    updateCurrentFacility:(state,action)=>{
      state.facility = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount,updateCurrentFacility } = coreSlice.actions

export default coreSlice.reducer